import type { IRate } from "~/interfaces";

/**
 * Retrieve power credit rates based on the provided value.
 *  This function filters power credit rates excluding the provided value.
 */
export const powerCreditRates = (value: string | number) => {
  const sortedPrices = [getModel(POWER_CREDIT_MIN_CREDIT), getModel(POWER_CREDIT_MAX_CREDIT)]
    .slice()
    .sort((a, b) => a.rate - b.rate);
  return sortedPrices.filter((val: IRate) => {
    return val.imp_finanz !== Number(value);
  });
};

/**
 * Retrieve limited offers rates based on the provided value.
 * This function filters limited offers rates based on the provided value.
 */
export const limitedOffersRates = (value: string | number) => {
  const prices = getPriceValues();
  const sortedPrices = prices.slice().sort((a: IRate, b: IRate) => b.rate - a.rate);

  const isMinPrice =
    prices.map((p: IRate) => p.imp_finanz).sort((a: number, b: number) => a - b)[0] ===
    Number(value);

  const isMaxPrice =
    prices.map((p: IRate) => p.imp_finanz).sort((a: number, b: number) => b - a)[0] ===
    Number(value);

  if (isMaxPrice || isMinPrice) {
    return [];
  }

  if (Number(value) <= POWER_CREDIT_MAX_CREDIT) {
    return [getModel(6000),getModel(7000)];
  }

  return [
    sortedPrices.find((p) => p.imp_finanz === Number(value) + 1000) ?? null,
    sortedPrices.find((p) => p.imp_finanz === Number(value) + 2000) ?? null,
  ].filter((p) => p !== null) as IRate[];
};

/**
 * Retrieve personal credit rates based on the provided value.
 *
 * This function filters and formats the personal credit rates based on the provided value.
 */
export const personalCreditRates = (value: string | number) => {
  const sortedPrices = getPriceValues().slice().sort((a: IRate, b: IRate) => b.rate - a.rate);
  const rates = sortedPrices.reduce((red: IRate[], val: IRate) => {
    if (val.imp_finanz === Number(value)) {
      red.push(formatPriceValues(val));
    }
    return red;
  }, []);
  rates.shift();
  return rates;
};